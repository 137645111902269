<template>
  <el-container>
    <el-main class="curriculum" v-if="isCurriculumShow">
      <div class="top">
        <div v-if="$store.getters.mobileSize" class="w-100 d-flex">
          <el-select class="w-100" v-model="selectedClassList" :multiple="true" collapse-tags placeholder="選擇顯示指定課程">
            <el-option v-for="item in selectedClassOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- <el-button color="#ffd964" class="ms-2 add_contact" @click="addAllDialogVisible = true" circle><i class="fal fa-plus-circle" style="color: #fff"></i></el-button> -->
        </div>
        <datepicker class="dateCalendar" inline="true" @input="dateSelected" :value="selectedDate"></datepicker>
      </div>
      <div class="main_part" v-if="!$store.getters.mobileSize">
        <div class="d-flex justify-content-center justify-content-between mb-2">
          <div style="width: 299px"></div>
          <div class="top_function_btn d-flex align-items-center">
            <el-button @click="changeWeek(-7)"><i class="fa-solid fa-angle-left"></i></el-button>
            <p class="curriculum_title mx-3 bold">{{ startDate }} ~ {{ endDate }}</p>
            <el-button @click="changeWeek(7)"><i class="fa-solid fa-angle-right"></i></el-button>
          </div>
          <div>
            <el-select v-model="selectedClassList" :multiple="true" collapse-tags placeholder="選擇顯示指定課程">
              <el-option v-for="item in selectedClassOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <!-- <el-button color="#ffd964" class="ms-2 add_contact" @click="addAllDialogVisible = true" circle><i class="fal fa-plus-circle" style="color: #fff"></i></el-button> -->
          </div>
        </div>
        <div class="main">
          <div class="period">
            <div class="title">時段</div>
            <div class="time morning">
              <span>上午</span>
              <span class="mt-1">(6~12)</span>
            </div>
            <div class="time afternoon">
              <span>下午</span>
              <span class="mt-1">(12~18)</span>
            </div>
            <div class="time evening">
              <span>晚上</span>
              <span class="mt-1">(18~24)</span>
            </div>
            <div class="time night">
              <span>凌晨</span>
              <span class="mt-1">(0~6)</span>
            </div>
          </div>
          <div class="week_day">
            <div class="week" :class="{ active: isToday(weekIndex) }" v-for="(week, weekIndex) in weekArray" :key="week">
              <div class="title">{{ week }} {{ getDateShowString(weekIndex) }}</div>
              <div class="time" @click.stop.prevent="showDialog(index, week)" v-for="(item, index) in 4" :key="item">
                <div
                  class="classItem"
                  :class="{
                    teacher: item.role == 'Teacher',
                    student: item.role == 'Student',
                    parent: item.role == 'Parent',
                  }"
                  v-for="item in displayData(weekIndex + 1, index)"
                  :key="item"
                >
                  <el-tooltip v-if="!$store.getters.mobileSize" :hide-after="100" placement="bottom">
                    <template #content>
                      <p>科目名稱：{{ item.classRoomName }}</p>
                      <p>課程代碼：{{ item.classRoomId }}</p>
                      <p>科目老師名稱：{{ item.teacherName }}</p>
                      <p>實體教室名稱：{{ item.onsiteClassRoomName }}</p>
                      <p>實體教室地址：{{ item.onsiteAddress }}</p>
                      <p>線上課程連結：<el-link :href="item.onlineLink" type="warning" target="_blank" v-if="item.onlineLink">前往連結</el-link></p>
                      <i v-if="item.role == 'Student'" class="fa-regular fa-timer me-2" style="cursor: pointer" @click="createClassRoomCheckIn(item)"></i>
                      <i v-if="setPermission(item.permission)" @click="deleteClassTime(item)" class="fal fa-trash-alt trash" style="cursor: pointer"></i>
                    </template>
                    <el-link class="mb-1" @click="toClassRoom(item)" :underline="false">
                      <div>
                        <div class="mb-1 d-flex align-items-center">
                          <div class="me-1">
                            <el-tag effect="dark" color="#c95f85" v-if="item.role == 'Teacher'">老師</el-tag>
                            <el-tag effect="dark" color="#3bafee" v-else-if="item.role == 'Student'">學生</el-tag>
                            <el-tag effect="dark" color="#5bd663" v-else-if="item.role == 'Parent'">家長</el-tag>
                            <el-tag effect="dark" color="#bcbcbc" v-else>建立者</el-tag>
                          </div>
                          <div>
                            <p v-if="setPermission(item.permission) && item.firstStudentName" class="student">
                              <span>學生: {{ item.firstStudentName }}. . .</span>
                            </p>
                            <p v-else-if="!setPermission(item.permission)" class="teacher">老師: {{ item.teacherName }}</p>
                          </div>
                        </div>
                        <span class="clerk me-1">{{ item.classTime.startTime }}~{{ item.classTime.endTime }} </span>
                        <p class="mt-1 classRoomName">
                          {{ item.classRoomName }}
                        </p>
                      </div>
                    </el-link>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="showSection" v-else>
        <div class="selectedShowSection mt-1 mb-2">
          <div class="selectedShowDate">{{ selectedShowDate }}</div>
          <div
            :class="{
              teacher: item.role == 'Teacher',
              student: item.role == 'Student',
              parent: item.role == 'Parent',
            }"
            @click="toClassRoom(item)"
            class="d-flex justify-content-between align-items-center selectedClassInfoData"
            v-for="item in selectedClassInfoData"
            :key="item"
          >
            <div class="d-flex">
              <div
                class="role"
                :class="{
                  teacher: item.role == 'Teacher',
                  student: item.role == 'Student',
                  parent: item.role == 'Parent',
                }"
              >
                <span v-if="item.role == 'Teacher'">老師</span>
                <span v-else-if="item.role == 'Student'">學生</span>
                <span v-else-if="item.role == 'Parent'">家長</span>
                <span v-else>建立者</span>
              </div>
              <div class="middle d-flex flex-column justify-content-center align-items-start">
                <div class="middle_role">
                  <p v-if="setPermission(item.permission) && item.firstStudentName" class="student">
                    <span>學生: {{ item.firstStudentName }}. . .</span>
                  </p>
                  <p v-else-if="!setPermission(item.permission)" class="teacher">老師: {{ item.teacherName }}</p>
                </div>
                <p class="mt-1 classRoomName">{{ item.classRoomName }}</p>
              </div>
            </div>
            <span class="time">{{ item.classTime.startTime }}~{{ item.classTime.endTime }} </span>
          </div>
        </div>
        <div class="bottomShowSection">
          <p class="title">本週課表</p>
          <div v-for="x in Object.keys(selectedClassWeekInfoData)" :key="x">
            <div class="selectedShowDate">{{ x }}</div>
            <div
              :class="{
                teacher: item.role == 'Teacher',
                student: item.role == 'Student',
                parent: item.role == 'Parent',
              }"
              @click="toClassRoom(item)"
              class="d-flex justify-content-between align-items-center selectedClassInfoData"
              v-for="item in selectedClassWeekInfoData[x]"
              :key="item"
            >
              <div class="d-flex">
                <div
                  class="role"
                  :class="{
                    teacher: item.role == 'Teacher',
                    student: item.role == 'Student',
                    parent: item.role == 'Parent',
                  }"
                >
                  <span v-if="item.role == 'Teacher'">老師</span>
                  <span v-else-if="item.role == 'Student'">學生</span>
                  <span type="danger" v-else-if="item.role == 'Parent'">家長</span>
                  <span v-else>建立者</span>
                </div>
                <div class="middle d-flex flex-column justify-content-center align-items-start">
                  <div class="middle_role">
                    <p v-if="setPermission(item.permission) && item.firstStudentName" class="student">
                      <span>學生: {{ item.firstStudentName }}. . .</span>
                    </p>
                    <p v-else-if="!setPermission(item.permission)" class="teacher">老師: {{ item.teacherName }}</p>
                  </div>
                  <p class="mt-1 classRoomName">{{ item.classRoomName }}</p>
                </div>
              </div>
              <span class="time">{{ item.classTime.startTime }}~{{ item.classTime.endTime }} </span>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <router-view v-else />
    <el-dialog v-model="addAllDialogVisible" title="新增課程" @close="closeDialog" :width="$store.getters.mobileSize ? '90%' : '50%'">
      <div class="classTime">
        <div class="mb-2">
          <el-select class="w-100 mb-2" v-model="classValue" placeholder="選擇課程">
            <el-option v-for="item in selectedClassOption" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <div class="mb-2 d-flex startEndDate align-items-center">
            <el-date-picker :disabled="true" v-model="dialogAddItem.dateRange" type="daterange" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期" />
          </div>
          <div class="d-flex">
            <el-checkbox :disabled="Object.keys(dialogAddItem).length == 0" class="me-2" v-model="dialogIsRepeat">重複</el-checkbox>
            <el-select :disabled="Object.keys(dialogAddItem).length == 0" v-if="dialogIsRepeat" class="w-100 mb-2" :persistent="false" v-model="dialogWeekValue" multiple placeholder="選擇開課星期">
              <el-option v-for="(item, index) in $store.state.common.weekOptions" :key="item.value" :label="item.label" :value="index + 1" />
            </el-select>
            <el-date-picker class="w-100 mb-2" v-else :disabledDate="disabledSingleDateFun" v-model="dialogSingleDate" type="date" placeholder="選擇日期" />
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <el-time-select :disabled="Object.keys(dialogAddItem).length == 0" v-model="dialogAddItem.startTime" :max-time="dialogAddItem.endTime" class="w-100 mb-2 mb-lg-0 me-lg-2" placeholder="開始時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
              <el-time-select :disabled="Object.keys(dialogAddItem).length == 0" v-model="dialogAddItem.endTime" :min-time="dialogAddItem.startTime" class="w-100 ms-2 mb-2 mb-lg-0" placeholder="結束時間" start="00:30" :step="$store.state.classRoomTimeSelectStep" end="24:00" />
            </div>
            <el-button type="primary" v-if="!$store.getters.mobileSize" class="ms-lg-2" @click="addWeekTime()">新增</el-button>
          </div>
          <el-button type="primary" v-if="$store.getters.mobileSize" class="ms-lg-2 w-100" @click="addWeekTime()">新增</el-button>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import Storage from "../../../models/storage";
const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export default {
  name: "Curriculum_New",
  components: {
    Datepicker,
  },
  data() {
    return {
      dialogTitle: "新增課程時間",
      mobileInfoTitle: "課程資訊",
      addAllDialogVisible: false,
      mobileInfoVisible: false,
      dialogAddItem: {},
      dialogIsRepeat: true,
      dialogWeekValue: [],
      dialogSingleDate: new Date(),
      setStartTime_1: "",
      setEndTime_1: "",
      setStartTime_2: "",
      startTime: "",
      endTime: "",
      classValue: "",
      classList: [],
      curriculumData: [],
      weekArray: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
      sourceData: [],
      clickWeek: "",
      payCheckData: {
        classRoomPaymentInfoList: [],
        classRoomCheckInfoList: [],
        studentList: [],
      },
      today: null,
      startDate: "",
      endDate: "",
      selectedDate: new Date(),
      selectedClassList: [],
      selectedShowWeek: "",
      showWeekDay: ["(日)", "(一)", "(二)", "(三)", "(四)", "(五)", "(六)"],
      calendarMonth: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  async mounted() {
    const vm = this;
    this.log_PageInfo("Curriculm", "3");
    Storage.set("breadcrumb_from", {
      first: "Curriculum",
    });

    vm.startDate = dayjs().weekday(1).format("YYYY-MM-DD");
    vm.endDate = dayjs().weekday(7).format("YYYY-MM-DD");

    await vm.initData();
    vm.today = dayjs(new Date());

    vm.timeout = setTimeout(() => {
      vm.selectedClassList = []
      vm.selectedClassList = vm.curriculumData.map(x => x.classRoomId);
      vm.selectedClassList.unshift(0);
      vm.selectedClassList = [...new Set(vm.selectedClassList)];
      // 增加日曆點點
      vm.addCalendarDot();
    }, 1000);

    // 日曆點點功能
    document.querySelector(".vuejs3-datepicker .prev").addEventListener("click", () => {
      var text = document.querySelector(".vuejs3-datepicker .day__month_btn.up").innerText.split(" ");
      var year = text[1];
      var month = vm.calendarMonth.findIndex(x => x == text[0]) + 1;
      this.addCalendarDot(year, month);

      var newDate = new Date(year, month - 1, 1);
      var mon = dayjs(newDate).weekday(1);
      var sun = dayjs(newDate).weekday(7);
      vm.startDate = mon.format("YYYY-MM-DD");
      vm.endDate = sun.format("YYYY-MM-DD");
      vm.selectedDate = newDate;
      vm.initData();
      this.weekNum = 0;
    });

    document.querySelector(".vuejs3-datepicker .next").addEventListener("click", () => {
      var text = document.querySelector(".vuejs3-datepicker .day__month_btn.up").innerText.split(" ");
      var year = text[1];
      var month = vm.calendarMonth.findIndex(x => x == text[0]) + 1;
      this.addCalendarDot(year, month);

      var newDate = new Date(year, month - 1, 1);
      var mon = dayjs(newDate).weekday(1);
      var sun = dayjs(newDate).weekday(7);
      vm.startDate = mon.format("YYYY-MM-DD");
      vm.endDate = sun.format("YYYY-MM-DD");
      vm.selectedDate = newDate;
      vm.initData();
      this.weekNum = 0;
    });
  },
  watch: {
    classValue(newVal) {
      if (newVal == "") return;
      this.dialogAddItem = this.curriculumData.find(x => x.classRoomId == newVal);
      console.log(newVal);
    },
    selectedClassList(newVal, oldVal) {
      if (newVal.length == this.selectedClassOption.length) return;
      if (newVal.includes(0)) {
        if (newVal.length == 1 || !oldVal.includes(0)) this.selectedClassList = this.selectedClassOption.map(x => x.value);
        else if (newVal.length < this.selectedClassOption.length) this.selectedClassList = this.selectedClassList.filter(x => x != 0);
        else this.selectedClassList = [];
      } else {
        if (newVal.length == this.selectedClassOption.length - 1 && oldVal.length == this.selectedClassOption.length) this.selectedClassList = [];
        else if (newVal.length == this.selectedClassOption.length - 1) {
          this.selectedClassList.push(0);
          this.selectedClassList.sort();
        } else this.selectedClassList = newVal;
      }
    },
    selectedDate() {
      this.addCalendarDot();
    },
  },
  computed: {
    isCurriculumShow() {
      return this.$route.path === "/ChargeMeContactBook/Curriculum" || this.$route.path === "/ChargeMeContactBook/Curriculum/";
    },
    selectedClassInfoData() {
      const vm = this;
      var result = vm.curriculumData.filter(x => dayjs(x.classTime.date).format("YYYYMMDD") == dayjs(vm.selectedDate).format("YYYYMMDD"));
      // if (vm.selectedClassList.length == 0) return result;
      // else return result.filter(x => vm.selectedClassList.includes(x.classRoomId));
      return result.filter(x => vm.selectedClassList.includes(x.classRoomId));
    },
    selectedClassWeekInfoData() {
      const vm = this;
      var origin = vm.curriculumData;
      // if (vm.selectedClassList.length != 0) origin = origin.filter(x => vm.selectedClassList.includes(x.classRoomId));
      origin = origin.filter(x => vm.selectedClassList.includes(x.classRoomId));

      var result = origin.reduce((group, item) => {
        var temp = `${dayjs(item.classTime.date).format("YYYY/MM/DD")} ${vm.showWeekDay[dayjs(item.classTime.date).day()]}`;
        group[temp] = group[temp] ?? [];
        group[temp].push(item);
        return group;
      }, {});
      return result;
    },
    selectedShowDate() {
      const vm = this;
      return `${dayjs(vm.selectedDate).format("YYYY/MM/DD")} ${vm.showWeekDay[dayjs(vm.selectedDate).day()]}`;
    },
    selectedClassOption() {
      const vm = this;
      var result = vm.curriculumData
        .map(x => ({ label: x.classRoomName, value: x.classRoomId }))
        .filter((obj, index, self) => {
          return index === self.findIndex(o => o.label === obj.label && o.value === obj.value);
        });
      result.unshift({ label: "全選課程", value: 0 });
      return result;
    },
  },
  methods: {
    getDateShowString(index) {
      const vm = this;
      return dayjs(new Date(vm.startDate)).add(index, "day").format("MM/DD");
    },
    addCalendarDot(defaultYear = null, defaultMonth = null) {
      const vm = this;
      var month;
      if (defaultMonth == null) month = dayjs(vm.selectedDate).$M + 1;
      else month = defaultMonth;

      var year;
      if (defaultYear == null) year = dayjs(vm.selectedDate).$y;
      else year = defaultYear;

      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTimeDot?year=${year}&month=${month}`)
        .then(res => {
          console.log(res.data);
          const numbersArray = res.data;
          const spanElements = document.querySelectorAll(".vuejs3-datepicker .cell.day");

          for (let i = 0; i < spanElements.length; i++) {
            const spanContent = parseInt(spanElements[i].innerText);
            if (numbersArray.includes(spanContent)) {
              spanElements[i].classList.add("matched");
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteClassTime(item) {
      const vm = this;
      let data = {
        classRoomId: item.classRoomId,
        classTimeIdList: [item.classTime.id],
        isDeleteAll: false,
      };
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
        .then(res => {
          console.log(res);
          vm.curriculumData = vm.curriculumData.filter(x => x.classTime.id != item.classTime.id);
          vm.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          vm.notify("error", `删除失敗！`);
        });
    },
    changeWeek(x) {
      const vm = this;
      var mon = dayjs(vm.selectedDate).weekday(1 + x);
      var sun = dayjs(vm.selectedDate).weekday(7 + x);
      vm.startDate = mon.format("YYYY-MM-DD");
      vm.endDate = sun.format("YYYY-MM-DD");
      vm.selectedDate = new Date(vm.startDate);
      vm.addCalendarDot();
      vm.initData();
    },
    toClassRoom(item) {
      this.$router.push(`/ChargeMeContactBook/ClassRoomCenter/ClassRoom/${item.classRoomId}`);
    },
    addWeekTime() {
      const vm = this;
      var classTime = {};
      var classRoomList = {
        date: [],
        startTime: "",
        endTime: "",
      };
      console.log(vm.dialogAddItem);
      if (vm.dialogAddItem.startDate == null || vm.dialogAddItem.endDate == null) {
        vm.notify("warning", "請先選擇好開始日期與結束日期！");
        return;
      }
      if (vm.dialogAddItem.startTime == "" || vm.dialogAddItem.endTime == "" || (vm.dialogIsRepeat && vm.dialogWeekValue.length == 0) || (!vm.dialogIsRepeat && vm.dialogSingleDate == null)) {
        vm.notify("warning", "請先選擇好日期與時間！");
        return;
      }

      if (vm.dialogIsRepeat) {
        var dates = vm.getDateRangeResult(vm.dialogWeekValue);
        dates.forEach(x => {
          classRoomList.date.push(x);
        });
        classRoomList.startTime = vm.dialogAddItem.startTime;
        classRoomList.endTime = vm.dialogAddItem.endTime;
      } else {
        var singleDate = new Date(vm.dialogSingleDate);
        classTime = {
          Date: singleDate,
          StartTime: vm.dialogAddItem.startTime,
          EndTime: vm.dialogAddItem.endTime,
        };
      }

      let data = {
        ClassRoomId: vm.dialogAddItem.classRoomId,
        ClassTime: classTime,
        ClassTimeList: classRoomList,
        IsRepeat: vm.dialogIsRepeat,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
        .then(res => {
          console.log(res.data);
          vm.initData();
          vm.addAllDialogVisible = false;
          vm.$message({
            type: "success",
            message: `新增完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `新增失敗！`);
        });
    },
    getDateRangeResult(weekDay) {
      const vm = this;
      var newArray = weekDay.map(x => {
        if (x == 7) {
          return 0;
        } else {
          return x;
        }
      });
      var resultDates = [];
      var currentDate = new Date(vm.dialogAddItem.classTime.startDate.getTime());
      while (currentDate <= vm.dialogAddItem.classTime.endDate) {
        var dayOfWeek = currentDate.getDay();
        if (newArray.includes(dayOfWeek)) {
          resultDates.push(dayjs(currentDate).add(8, "hour"));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return resultDates;
    },
    disabledSingleDateFun(time) {
      const vm = this;
      if (vm.dialogAddItem.startDate != null) {
        if (dayjs(vm.dialogAddItem.startDate).format("YYYY") == "1901") return;
        return new Date(dayjs(time)).getTime() < vm.dialogAddItem.startDate.getTime() || new Date(dayjs(time)).getTime() > vm.dialogAddItem.endDate.getTime();
      }
    },
    dateSelected(newDate) {
      const vm = this;
      vm.selectedDate = newDate;
      var startDate = new Date(vm.startDate);
      var endDate = new Date(vm.endDate);
      if (startDate <= new Date(dayjs(newDate).format("YYYY-MM-DD")) && new Date(dayjs(newDate).format("YYYY-MM-DD")) <= endDate) {
        return;
      } else {
        var mon = dayjs(newDate).weekday(1);
        var sun = dayjs(newDate).weekday(7);
        vm.startDate = mon.format("YYYY-MM-DD");
        vm.endDate = sun.format("YYYY-MM-DD");
        vm.initData();
      }
    },
    addPayment(item) {
      if (item.length == 0) return 0;
      var result = 0;
      item.forEach(x => {
        result += Number(x.classRoomNumber);
      });
      return result;
    },
    createClassRoomCheckIn(o) {
      const vm = this;
      console.log(o);
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/${o.classRoomId}/${vm.currentUser().id}`)
        .then(res => {
          // console.log(res.data);
          if (Object.keys(res.data).length != 0) {
            Object.assign(vm.payCheckData, res.data);
          }
          if (this.addPayment(vm.payCheckData.studentList[0].classRoomPaymentInfoList) == vm.payCheckData.studentList[0].classRoomCheckInList.length) {
            this.notify("error", `無法打卡，請確認是否還有剩餘堂數！`);
            return;
          } else {
            var day = dayjs();
            const data = {
              StudentId: vm.currentUser().id,
              CheckedTime: day.add(8, "hour"),
              IsDueTime: false,
            };
            vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/CheckIn`, data)
              .then(res => {
                console.log(res.data);
                vm.notify("success", `打卡成功！`);
              })
              .catch(err => {
                vm.notify("error", `打卡失敗，${err.response.data.Msg}!`);
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    closeDialog() {
      const vm = this;
      vm.mobileInfoVisible = false;
      vm.classValue = "";
      vm.clickWeek = "";
      vm.startTime = "";
      vm.endTime = "";

      vm.addAllDialogVisible = false;
      vm.dialogAddItem = {};
      vm.dialogIsRepeat = true;
      vm.dialogWeekValue = [];
      vm.dialogSingleDate = new Date();
    },
    isToday(weekIndex) {
      var temp = dayjs(this.selectedDate).day();
      if (temp == 0) temp = 7;
      return temp == weekIndex + 1;
    },
    showDialog(index, week) {
      const vm = this;
      vm.clickWeek = week;
      let time_1 = ["06:00", "12:00", "18:00", "24:00"];
      let time_1_end = ["05:30", "11:30", "17:30", "23:30"];
      let time_2 = ["06:30", "12:30", "18:30", "24:30"];
      let range = ["上午(06:00~12:00)", "下午(12:00~18:00)", "晚上(18:00~24:00)", "凌晨(00:00~06:00)"];
      vm.setStartTime_1 = time_1[index];
      vm.setEndTime_1 = time_1_end[index + 1];
      vm.setStartTime_2 = time_2[index];
      if (vm.setStartTime_1 == "24:00") {
        vm.setStartTime_1 = "00:00";
        vm.setEndTime_1 = "05:30";
        vm.setStartTime_2 = "00:30";
      }
      vm.dialogTitle = `新增課程時間 - ${week} ${range[index]}`;
      vm.dialogVisible = true;
    },
    displayData(weekday, period) {
      const vm = this;
      var weekOptions = ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"];
      var timeOptions = [6, 12, 18, 24];

      var result = vm.curriculumData.filter(x => {
        var index = dayjs(x.classTime.date).day();
        if (index == 0) index = 7;
        var week = weekOptions[index - 1];
        var weekIndex = weekOptions.findIndex(x => x == week) + 1;

        var time = Number(x.classTime.startTime.split(":")[0]);
        var firstTime = timeOptions[period];
        var secondTime = timeOptions[period + 1];
        if (firstTime == 24) {
          firstTime = 0;
          secondTime = 6;
        }
        var isTimeCorrect = time >= firstTime && time < secondTime;
        return weekIndex == weekday && isTimeCorrect;
      });

      // if (vm.selectedClassList.length == 0) return result;
      // else return result.filter(x => vm.selectedClassList.includes(x.classRoomId));
      return result.filter(x => vm.selectedClassList.includes(x.classRoomId));
    },
    initData() {
      const vm = this;

      // 初始化
      vm.addAllDialogVisible = false;
      vm.dialogAddItem = {};
      vm.dialogIsRepeat = true;
      vm.dialogWeekValue = [];
      vm.dialogSingleDate = new Date();

      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime?startDate=${vm.startDate.replace(/-/g, "")}&endDate=${vm.endDate.replace(/-/g, "")}`)
        .then(res => {
          // console.log(res.data);
          vm.curriculumData = [];
          vm.classList = [];
          vm.sourceData = res.data;
          if (res.data.length != 0) {
            res.data.forEach(x => {
              // curriculumData
              if (x.classTime.length != 0) {
                x.classTime.forEach(y => {
                  vm.curriculumData.push({
                    classRoomId: x.classRoomId,
                    onlineLink: x.onlineLink,
                    onsiteAddress: x.onsiteAddress,
                    onsiteClassRoomName: x.onsiteClassRoomName,
                    startDate: x.startDate == null ? null : new Date(x.startDate),
                    endDate: x.endDate == null ? null : new Date(x.endDate),
                    classTime: y,
                    classRoomName: x.classRoomName,
                    teacherName: x.teacherName,
                    permission: x.permission,
                    role: x.role,
                    originClassTime: x.classTime,
                    firstStudentName: x.firstStudentName,
                    dateRange: [x.startDate == null ? null : new Date(x.startDate), x.endDate == null ? null : new Date(x.endDate)],
                    startTime: "",
                    endTime: "",
                  });
                });
              }
              if (vm.setPermission(x.permission)) {
                if (!x.isDeleted) {
                  vm.classList.push({
                    value: x.classRoomId,
                    label: x.classRoomName,
                  });
                }
              }
            });

            vm.selectedClassInfoData = vm.curriculumData.filter(x => dayjs(x.classTime.date).format("YYYYMMDD") == dayjs(vm.selectedDate).format("YYYYMMDD"));
            vm.selectedClassWeekInfoData = vm.curriculumData.reduce((group, item) => {
              group[dayjs(item.classTime.date).format("YYYY/MM/DD")] = group[dayjs(item.classTime.date).format("YYYY/MM/DD")] ?? [];
              group[dayjs(item.classTime.date).format("YYYY/MM/DD")].push(item);
              return group;
            }, {});
            // console.log(vm.selectedClassWeekInfoData);

            vm.selectedShowDate = `${dayjs(vm.selectedDate).format("YYYY/MM/DD")} ${vm.showWeekDay[dayjs(vm.selectedDate).day()]}`;

            // 排序
            vm.curriculumData.sort(function (a, b) {
              var week1 = dayjs(a.classTime.date).day();
              var week2 = dayjs(b.classTime.date).day();
              if (week1 == 0) week1 = 7;
              if (week2 == 0) week2 = 7;
              if (week1 != week2) return week1 - week2;

              var time1_1 = a.classTime.startTime.split(":")[0];
              var time2_1 = b.classTime.startTime.split(":")[0];
              if (time1_1 != time2_1) return time1_1 - time2_1;

              var time1_2 = a.classTime.startTime.split(":")[1];
              var time2_2 = b.classTime.startTime.split(":")[1];
              if (time1_2 != time2_2) return time1_2 - time2_2;

              var time1_3 = a.classTime.endTime.split(":")[0];
              var time2_3 = b.classTime.endTime.split(":")[0];
              if (time1_3 != time2_3) return time1_3 - time2_3;

              var time1_4 = a.classTime.endTime.split(":")[1];
              var time2_4 = b.classTime.endTime.split(":")[1];
              return time1_4 - time2_4;
            });
            // console.log(vm.curriculumData);
            vm.selectedClassList = []
            vm.selectedClassList = vm.curriculumData.map(x => x.classRoomId);
            vm.selectedClassList.unshift(0);
            vm.selectedClassList = [...new Set(vm.selectedClassList)];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  overflow: auto;
  width: 100%;
  height: 80vh;
  margin: auto;
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid #b2b2b2;
  background: #ffffff;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  .week_day {
    display: flex;
    width: 100%;
  }
  .week,
  .period {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #b2b2b2;
    flex-grow: 1;
  }
  .week {
    width: 100%;
  }
  .time {
    border-bottom: 1px solid #b2b2b2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 25%;
    // width: 12.95rem;
    padding: 0.2rem;
    transition: 0.2s;
    &.canNotAdd {
      cursor: default;
    }
  }
  .classItem {
    width: 100%;
    border: 2px solid #bcbcbc;
    border-radius: 0.5rem;
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.2s;
    &.teacher {
      border-color: #e786aa;
    }
    &.parent {
      border-color: #5bd663;
    }
    &.student {
      border-color: #3bafee;
    }
    &.teacher:hover,
    &.teacher:focus {
      background-color: #ec9bb9;
    }
    &.parent:hover,
    &.parent:focus {
      background-color: #9beba0;
    }
    &.student:hover,
    &.student:focus {
      background-color: #70bee8;
    }
    &:hover,
    &:focus {
      background-color: #ececec;
    }
  }
  .title {
    border-bottom: 1px solid #b2b2b2;
    padding: 0.5rem 0;
    font-weight: 900;
    // width: 12.95rem;
  }
  .period {
    flex-grow: 0;
    position: sticky;
    height: 100%;
    z-index: 1;
    left: 0;
    .title {
      width: unset;
    }
    .time {
      justify-content: center;
      cursor: default;
      width: 4rem;
      font-weight: 900;
      &:last-child {
        border: none;
      }
    }
  }
  .week .time {
    text-align: start;
    overflow: hidden auto;
    .el-link {
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
    .clerk {
      display: inline-block;
      font-weight: 900;
    }
    .student {
      color: #409eff;
    }
    .teacher {
      color: #c95f85;
    }
    .classRoomName {
      padding-top: 0.1rem;
      color: #000000c5;
      font-weight: 900;
    }
  }
  .week_day {
    display: flex;
    // width: 100%;
    overflow-x: auto;
    .week:last-child,
    .week .time:last-child {
      border: none;
    }
    .week.active {
      background-color: #f4f4f4d9;
    }
  }
}
:deep .el-dialog {
  .mobileInfo {
    text-align: start !important;
    p {
      padding: 0.5rem 0;
    }
  }
}
:deep .el-popper {
  display: block !important;
}

:deep .vuejs3-datepicker {
  margin: 1rem 0;
  .vuejs3-datepicker__calendar {
    width: 100% !important;
    .vuejs3-datepicker__calendar-topbar {
      background-color: #ffd964;
    }
    .selected {
      background-color: #ffd964;
    }
  }
  .vuejs3-datepicker__calendar-topbar {
    display: none;
  }
  .cell:not(.month):not(.year) {
    height: 2rem;
    line-height: 2rem;
  }
  .vuejs3-datepicker__calendar-actionarea {
    & > div {
      text-align: start;
    }
  }
  .cell.day.matched {
    &:not(.blank)::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background-color: rgb(122, 121, 15);
      border-radius: 50%;
      margin-top: -7px; /* 调整点的垂直位置 */
      margin-left: 50%; /* 调整点的水平位置 */
      transform: translateX(-50%);
    }
  }
}

.showSection {
  margin-top: 0.5rem;
  border: 2px solid #e2e2e2;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  max-height: 395px;
  overflow: auto;
  font-size: 0.9rem;
  .selectedClassInfoData {
    border: 2px solid #bcbcbc;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.8rem 0;
    font-weight: bold;
    cursor: pointer;
    &.teacher {
      border-color: #e786aa;
    }

    &.teacher:hover,
    &.teacher:focus {
      background-color: #ec9bb9;
    }
    &.parent {
      border-color: #5bd663;
    }
    &.parent:hover,
    &.parent:focus {
      background-color: #9beba0;
    }
    &.student {
      border-color: #3bafee;
    }
    &.student:hover,
    &.student:focus {
      background-color: #70bee8;
    }
    &:hover,
    &:focus {
      background-color: #ececec;
    }
    transition: 0.2s;
    .role {
      width: 3rem;
      height: 3rem;
      background-color: #bcbcbc;
      border-radius: 1rem;
      color: #ffffff;
      font-size: 0.9rem;
      span {
        line-height: 3rem;
      }
      &.teacher {
        background-color: #c95f85;
      }
      &.student {
        background-color: #3bafee;
      }
      &.parent {
        background-color: #5bd663;
      }
    }
    .middle {
      margin-left: 1rem;
      .middle_role {
        p {
          color: #3bafee;
          &.teacher {
            color: #c95f85;
          }
        }
      }
    }
  }
  .selectedShowDate {
    margin-left: 0.5rem;
    margin-top: 1rem;
    text-align: start;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .bottomShowSection {
    border-top: 1px solid #a0a0a0;
    margin-top: 1rem;
    .title {
      margin-left: 0.5rem;
      margin-top: 1rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}
.curriculum {
  padding-bottom: 0 !important;
  width: 100vw;
  // overflow: hidden;
}
.curriculum_title {
  font-size: 1.2rem;
  font-weight: bold;
}
:deep .el-tag {
  border: none;
}
.main_part {
  width: 100%;
  // flex-grow: 1;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .curriculum {
    display: flex;
    .top {
      width: 20rem;
      margin-right: 2rem;
    }
  }
  :deep .vuejs3-datepicker {
    margin: 0;
    .vuejs3-datepicker__calendar {
      width: 20rem !important;
    }
  }
  .dateCalendar {
    margin-top: 2rem;
  }
}
</style>
